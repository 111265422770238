<template>
  <div>
    <search-header :currentActive="4"></search-header>
    <div class="container p-0 my-4">
      <img class="img-fluid" src="@/assets/img/ad_nongju_1.png">
    </div>

    <div class="container">
    	<div class="row">
    		<div class="col-12 py-2 border screen">
    			<div class="row mb-2 mt-2 align-content-center">
    				<div class="col-1 text-secondary mt-1">地区:</div>
    				<div class="col">
    					<div class="row">
    						<a href="javascript:;" class="mr-3 px-2 py-1 active">全部</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">湖南</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">北京</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">天津</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">河北</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">辽宁</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">黑龙江</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">上海</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">江苏</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">浙江</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">安徽</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">福建</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">江西</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">山东</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">河南</a>	
    						<a href="javascript:;" class="mr-3 px-2 py-1">湖北</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">广东</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">广西</a>	
    						<a href="javascript:;" class="mr-3 px-2 py-1">海南</a>
    					</div>
    				</div>
    			</div>
    			
    			<hr class="my-3" />
    			
    			<div class="row mb-2 align-content-center">
    				<div class="col-1 text-secondary mt-1">机械:</div>
    				<div class="col">
    					<div class="row">
    						<a href="javascript:;" class="mr-3 px-2 py-1 active">全部</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1 active">土壤耕整机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">排灌机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">种植机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">施肥/肥料机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">收获机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">植保机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">茶叶机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">粮油机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">果蔬机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">饲料机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">畜牧饲养机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">棉花机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">动力输送机械</a>
							<a href="javascript:;" class="mr-3 px-2 py-1">农机配件</a>
    					</div>
    				</div>
    			</div>
    
    			<hr class="my-3" />
    
    			<div class="row ">
    				<div class="col-1 text-secondary mt-1">方式:</div>
    				<div class="col">
    					<div class="row">
    						<a href="javascript:;" class="mr-3 px-2 py-1 active">全部</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">出售</a>
    						<a href="javascript:;" class="mr-3 px-2 py-1">出租</a>
    					</div>
    				</div>
    			</div>
    		</div>
    	
    		<div class="col-12 py-2 mt-3 border rounded">
    			<div class="row pt-2 pb-2">
    				<div class="col-1 text-secondary mt-1">排序:</div>
    				<div class="col">
    					<div class="row">
    						<a href="javascript:;" class="mr-4 px-2 py-1 rounded color-fz">最新 <b-icon icon="arrow-up-short"></b-icon></a>
    						<a href="javascript:;" class="mr-4 px-2 py-1 rounded">价格 <b-icon icon="arrow-down-short"></b-icon></a>
    					</div>
    				</div>
    			</div>
    			
    		</div>
    	</div>
    </div>
    <div class="container p-0">
      <div class="row my-4">
        <div class="col-9">
          <div class="row">
            <div class="col-4" v-for="item in 21" :key="item">
              <land></land>
            </div>
            <div class="col-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">上一页</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#">下一页</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="border-left menu-title px-3 mb-2 py-0">好地推荐</div>
          <div class="row">
            <div class="col-12 mb-3" v-for="(item, index) in 5" :key="index">
				<router-link  to="/needs/detail">  <land-let></land-let></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import land from "@/components/base/land";

export default {
  name: "",
  components: {
    land,
  },
};
</script>

<style scoped>
	.screen .active {
		background: #00cc00;
		color: #FFFFFF;
		border-radius: 6px;
	}
</style>
